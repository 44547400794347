body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

html,
body,
#root {
  height: 100%
}

/*html {*/
/*  scrollbar-color: lightGrey grey;*/
/*  scrollbar-width: thin;*/
/*}*/


.WindowScrollerWrapper {
  flex: 1 1 auto;
}

.react-pdf__Page__canvas {
  max-width: 720px;
  /*margin-left: auto;*/
  /*margin-right: auto;*/
}

@keyframes flash_animation {
  0%     {background-color:white;}
  50.0%  {background-color:rgba(0,0,0,.2);}
  100.0%  {background-color:white;}
}

.flash{
  -webkit-animation-name: flash_animation;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;

  animation-name: flash_animation;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.slidein {
  animation-duration: .5s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    /*top:-50px;*/
    opacity:.5
  }

  to {
    /*top:0;*/
    opacity:1
  }
}

.card {
  position: relative;
  top: 0;
  overflow: hidden;
  padding: 4em;
  margin: auto;
  max-width: 30%;
  min-width: 300px;
  height: 100%;
  color: #212121;
  background-color: #fff;
  backface-visibility: hidden;
  box-shadow: 0 10px 35px rgba(50, 50, 93, 0.1), 0 2px 15px rgba(0, 0, 0, 0.07);
}

.App-logo {
  height: 40px;
  padding: 0 15px;
  pointer-events: none;
}

.Shadow1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px, rgba(10, 37, 64, 0.35) 0 -2px 6px 0 inset;
}

.rmdp-week {
  width: 320px;
  max-width: 450px;
  height: 35px;
}

.rmdp-wrapper {
  margin: 10px 0;

}

/*.rmdp-day-picker {*/
/*  height: 100%;*/
/*}*/

@media (min-width: 350px) {
  .rmdp-week {
    width: 300px;
    height: 35px;
  }

  .rmdp-wrapper {
    margin: 10px 0;
  }
}
